<template>
  <div class="home">
    <v-container>
      <v-row no-gutters>
        <v-col xs="0"></v-col>
        <v-col md="6" sm="10" cols="12">
          <v-card color="grey darken-2">
            <v-card-title primary-title class="white--text">Hello there! My name is Erin Rivas.</v-card-title>
            <v-card-text class="white--text">
              I am a workaholic who is passionate about building things, solving problems, and mentoring others.
              I am a freelance JavaScript developer and digital tinkerer, focused on open source or non-profit projects.
              I like to aim high, and put everything into my work. This site is a showcase of my skills and hobby projects.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col xs="0"></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {}
}
</script>
